import React from 'react';
import BrownChar from '../assets/img/brown-char.png';
import bg from '../assets/img/forgotten-bg.png'


class Forgotten extends React.Component {
    render() {
        return (
            <div className="container-fluid" style={{backgroundImage: `url(${bg})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="char-box">
                                <div className="char-image">
                                    <img src={BrownChar} alt="Forgotten" />
                                </div>
                                <div className="char-frame-text-right">
                                    <div className="small-heading">Genesis Forgotten NFT</div>
                                    <div className="description">
                                        <span className="important">66 Genesis Forgotten NFTs were minted!</span>
                                                &nbsp;
                                        <span className="white">The cost of a Forgotten NFT was 0.15 ETH</span>
                                    </div>
                                    <div className="small-heading3">The Forgotten</div>
                                    <div className="description">
                                        The origins of the Forgotten faction remain a well-kept mystery, buried in the depths of history. Their insanity guides them, their secrets define them and we don’t even know where they hide them. Having lost their sense of direction between good and evil, they thrive within the chaos that ensues between them. Guided by their selfish goals and gains, the Forgotten have a way of playing everyone’s games.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Forgotten;
