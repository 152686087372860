import React from 'react';
import Logo from '../assets/img/logo.png';




class Nav extends React.Component {
    constructor() {
        super();
        this.state = {
            menuIsOpen: false
        };
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
    }

    handleToggleMenu() {
        this.setState({
            menuIsOpen: !this.state.menuIsOpen
        })
    }

    render() {
        return (
            <nav className={"nav " + (this.state.menuIsOpen ? 'mobile-open' : '')}>
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <a className="logo" href="https://www.castleofblackwater.com/">
                            <img src={Logo} alt="BlackWater" />
                        </a>
                        <div className="socials">
                            <a href="https://discord.gg/8zW9PVsWwa" className="icon-discord"></a>
                            <a href="https://twitter.com/Blackwater_Town" className="icon-twitter"></a>
                            <a href="https://medium.com/@Blackwater_Town" className="icon-medium"></a>
                            <a href="https://www.instagram.com/castleofblackwater" className="icon-instagram"></a>
                            <a href="https://www.reddit.com/r/Castle_Of_Blackwater" className="icon-reddit"></a>
                        </div>
                        <div className="menu">
                            <div className="menu-item">
                                <a href="/">Mint</a>
                            </div>

			    <div className="menu-item">
                                <a href="/stake">Stake</a>
                            </div>
                            <div className="menu-item">
                                <a href="https://www.castleofblackwater.com/">Website</a>
                            </div>
                            
                            <div className="menu-item">
                                <a href="https://www.castleofblackwater.com/faq">Support</a>
                            </div>

                        </div>
                        <div className="hamburger" onClick={this.handleToggleMenu}>
                            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 23 23" width="32px" height="32px"><path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"/></svg>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Nav;
