import React, { Component} from 'react';
import Earl from "../assets/img/earl-new.png";
import styled from "styled-components";


const Container = styled.div`
    max-height: 80vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Box = styled.div`
width: 500%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
class Information extends Component {
    render() {
        return (
            <div className="container-fluid" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="char-box">
                                <Container>
                                    <Box>
                                    <div className="small-heading">Mint Experience</div>
                                    <div className="description" >
                                        <p>
                                            <span className="white">The mint experience is only available on desktop/laptop, it is NOT available on mobile.</span>
                                        </p>
                                        &nbsp;
                                        <p>
                                        	Minting through the Mint Experience is not available anymore, however you can still play the Mint Experience.
					</p>
                                        <div className="dis-buttons">
                                            <a href="https://mint-experience.castleofblackwater.com/" className="btn-large"><span>MINT EXPERIENCE</span></a>
                                        </div>

                                    </div>
                                    </Box>

                                </Container>
                                <div className="char-image">
                                    <img src={Earl} alt="Protectors" />
                                </div>
                           </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Information;
