import "bootstrap/dist/js/bootstrap.js";
import "./assets/sass/style.scss";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Stake from "./Pages/Stake.js";
import Mint from "./Pages/Mint.js";

import "./App.css";

// Workaround for a Webpack bug where it removed Buffer
window.Buffer = Buffer;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Mint />} />
        <Route path="/stake" element={<Stake />} />
      </Routes>
    </Router>
  );
}

export default App;
