import React from 'react';
import LogoHeader from '../assets/img/logo-header.png';


class Header extends React.Component {
    render() {
        return (
            <header id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 header-content">
                            <div>
				<a href="/" className="logo-image">
                                	<img src={LogoHeader} alt="BlackWater" />
                            	</a>
			     </div>	
                            <div className="header-subtext2">
                                <a>Mint is over. Thank you for the support!</a>
                                &nbsp;
                                &nbsp;
                                <br />
                            </div>

                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
