import React, {Component} from "react";
import Nav from "../Components/Nav.js";
import Footer from "../Components/Footer.js";
import StakeInformation from "../Components/StakeInformation.js";
import StakeProtector from "../Components/StakeProtector.js";
import StakeSatanic from "../Components/StakeSatanic.js";
import StakeForgotten from "../Components/StakeForgotten.js";

class Stake extends Component {
    render() {
        return (
            <div>
                <Nav />
                <StakeInformation/>
                <StakeProtector />
                <StakeSatanic />
                <StakeForgotten />
                <Footer />
            </div>
        );
    }
}


export default Stake;