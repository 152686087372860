import React from 'react';
import LogoFotter from '../assets/img/logo-footer.png';

class Footer extends React.Component {
    render() {
        return (
           <div>
            <footer>
                <section className="footer-copyright">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="copyright-left">
                                    <a href="https://fairplaycollective.com">Member of the <span>Fair Play Collective.</span></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 text-center">
                                <a href=""><img src={LogoFotter} alt="" /></a>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="copyright-right">
                                    All rights reserved: <span>Castle of Blackwater B.V.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
           </div>
        );
    }
}

export default Footer;
