import React from 'react';
import YellowChar from '../assets/img/yellow-char.png';
import bg from '../assets/img/satanic-bg.png'

class Satanic extends React.Component {
    render() {
        return (
            <div className="container-fluid" style={{backgroundImage: `url(${bg})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="char-box">
                                <div className="char-frame-text-right">
                                    <div className="small-heading">Genesis Satanic NFT</div>
                                    <div className="description">
                                        <span className="important">134 Genesis Satanic NFTs were minted!</span>
                                                &nbsp;
                                        <span className="white">The cost of a Genesis NFT was 0.1 ETH</span>
                                    </div>
                                    <div className="small-heading3">The Satanic</div>
                                    <div className="description">
                                        Were the first faction to rise up in Blackwater Town. Created and led by the great Earlwind Axelot himself,
                                        it distinguishes itself through teamwork, compassion and trust. When challenging the castle,
                                        Protector faction members work together to complete challenges and protect each other from harm.

                                    </div>
                                </div>
                                <div className="char-image">
                                    <img src={YellowChar} alt="Satanic" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Satanic;
