import React, {Component} from "react";
import Header from "../Components/Header.js";
import Protectors from "../Components/Protectors.js";
import Satanic from "../Components/Satanic.js";
import Forgotten from "../Components/Forgotten.js";
import Nav from "../Components/Nav.js";
import Footer from "../Components/Footer.js";
import Information from "../Components/Information.js";

class Mint extends Component {
    render() {
        return (
            <div>
                <Nav />
                <Header />
                <Information/>
                <Protectors />
                <Satanic />
                <Forgotten />
                <Footer />
            </div>
        );
    }
}


export default Mint;
