import React from 'react';
import RedChar from '../assets/img/red-char.png';
import bg from '../assets/img/protectors-bg.png';



class Protectors extends React.Component {



    render() {



        return (
            <div className="container-fluid" style={{backgroundImage: `url(${bg})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="char-box">
                                <div className="char-image">
                                    <img src={RedChar} alt="Protectors" />
                                </div>
                                <div className="char-frame-text-right">
                                    <div className="small-heading">Genesis Protector NFT</div>
                                    <div className="description">
                                        <span className="important">400 Genesis Protector NFTs were minted!</span>
						&nbsp;
                                        <span className="white">&nbsp;The cost of a Protector NFT was 0.05 ETH</span>
                                    </div>
                                    <div className="small-heading3">The Protectors</div>
                                    <div className="description">
                                        Were the first faction to rise up in Blackwater Town. Created and led by the great Earlwind Axelot himself,
                                        it distinguishes itself through teamwork, compassion and trust. When challenging the castle,
                                        Protector faction members work together to complete challenges and protect each other from harm.

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Protectors;
