import React, { Component} from 'react';
import Earl from "../assets/img/earl-new.png";
import arrow from "../assets/img/down.png";
import styled from "styled-components";


const Container = styled.div`
    max-height: 80vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
`

const Box = styled.div`
width: 500%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
class StakeInformation extends Component {
    render() {
        return (
            <div className="container-fluid" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="char-box">
                                <Container>
                                    <Box>
                                        <div className="small-heading">Staking Information</div>
                                        <div className="description" >
                                            <p>
                                                <span className="white">On this page you can claim your Genesis NFT staking credits.</span>
                                            </p>
                                            &nbsp;
                                            <p>
                                                Simply connect your wallet to the page and scroll down to the factions that you own an NFT off.&nbsp;
                                                <span className="important">Pressing the 'Claim' button will transfer the credits from the NFT to your wallet.</span>&nbsp;
                                            </p>
                                            &nbsp;
                                            <p>

                                                All NFT's have been accumulating credits since the day of our Genesis sale, and will continue to do so until the day we launch our own cryptocurrency.
                                            </p>
                                            &nbsp;
                                            <p>
                                                The rate of credit accumulation depends on which faction the NFT belongs to. For more information about the staking rates, please visit:
                                                </p>
                                            &nbsp;
                                            <p>
                                                <span className="important">https://docs.castleofblackwater.com/nft/nft-staking-and-airdrop</span>


                                            </p>

                                        </div>
                                    </Box>


                                </Container>
                                <div className="char-image">
                                    <img src={Earl} alt="Protectors" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StakeInformation;