import React from "react";
import styled from "styled-components";
import RedChar from "../assets/img/red-char.png";
import bg from "../assets/img/protectors-bg.png";

import {
  StreamClaimingProvider,
  VestedHolderStreamClaimingProvider,
  VestedHolderStreamRate,
  StreamStatusBar,
  ConnectButton,
  DisconnectButton,
  SwitchChainButton,
  StreamClaimButton,
  StreamTotalClaimed,
  VestedHolderStreamTimeUnit,
  StreamEligibleNfts,
  VestedHolderStreamReleasedAmount,
  StreamClaimableAmount,
  RequireConnect,
} from "flair-sdk";

const chainId = "1";
const contractAddress = "0x08e8c27bfb9849bf9e8c7980762612a947d96d9d";

const Container = styled.div`
  max-height: 80vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class Protectors extends React.Component {
  render() {
    return (
      <div
        className="container-fluid"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="char-box-new">
              <div className="char-image">
                <img src={RedChar} alt="Protectors" />
              </div>
            <Container>

              <div className="char-frame-text-right">
                <div className="small-heading">Genesis Protector Stake</div>
                <div className="description pb-2">
                  <StreamClaimingProvider
                    chainId={Number(chainId)}
                    contractAddress={contractAddress}
                  >
                    <VestedHolderStreamClaimingProvider>
                      {({ data: { stream } }) => (
                        <>
                          <div className="small-heading flex items-center justify-between">
                            <div className="text-lg">
                              Rewards:
                              <VestedHolderStreamRate className="font-bold" />
                            </div>
                            <div className="text-lg">
                              Total rewarded:
                              <StreamClaimableAmount
                                calculationMode="OVERALL"
                                totalSupply={400}
                              />{" "}
                            </div>
                            <div className="text-lg">
                              Total claimed:
                              <StreamTotalClaimed calculationMode="OVERALL" />{" "}
                            </div>
                          </div>

                          <div className="flex items-center justify-between">
                            <RequireConnect
                              notConnectedView={
                                <div className="p-4">
                                  First connect your wallet, to see the staking
                                  rewards
                                </div>
                              }
                            >
                              <ul className="mt-8 flex flex-col gap-4">
                                <li className="flex gap-2">
                                  <span className="font-bold">Your NFTs</span>
                                  <StreamEligibleNfts />
                                </li>
                                <li className="flex flex-col">
                                  <div className="flex gap-2">
                                    <span className="font-bold">
                                      Your total claimed
                                    </span>
                                    <StreamTotalClaimed />
                                  </div>
                                  <small className="text-gray-400">
                                    (all previous claims)
                                  </small>
                                </li>
                                <li className="flex flex-col">
                                  <div className="flex gap-2">
                                    <span className="font-bold">
                                      Your released amount
                                    </span>
                                    <VestedHolderStreamReleasedAmount />
                                  </div>
                                  <small className="text-gray-400">
                                    (claimable{" "}
                                    <VestedHolderStreamTimeUnit className="inline" />
                                    )
                                  </small>
                                </li>
                                <li className="flex flex-col gap-1">
                                  <span className="font-bold">
                                    Claimable now for you
                                  </span>
                                  <StreamClaimableAmount className="text-2xl font-bold" />
                                </li>
                              </ul>
                            </RequireConnect>

                            <div className="mt-2">
                              {/* Claim button */}
                              <ConnectButton className="btn-large text-white">
                                <SwitchChainButton
                                  requiredChainId={Number(chainId)}
                                  className="btn-large text-white"
                                >
                                  <StreamClaimButton className="btn-large text-white" />
                                </SwitchChainButton>
                                <DisconnectButton className="btn-large text-white" />
                              </ConnectButton>

                              <div className="mt-4">
                                <StreamStatusBar />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </VestedHolderStreamClaimingProvider>
                  </StreamClaimingProvider>
                </div>
              </div>
            </Container>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default Protectors;
