import "./index.css";
import "./tailwind.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { FlairProvider } from "flair-sdk";

import App from "./App.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <FlairProvider>
    <App />
  </FlairProvider>
);
